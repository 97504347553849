body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ededed;
  padding-bottom: 60px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

button:focus {
  outline: none !important;
}

.choices__list.choices__list--single .choices__item.choices__item--selectable {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.choices__list.choices__list--single .choices__item.choices__item--selectable[data-deletable] {
  padding-right: 2em;
}

/** CSS for Permit rendering. The styles here are specified in the Form.IO designer */
.formio-form {
  .green {
    .card-header {
      background-color: #abebc6 !important;
    }

    .card-body {
      background-color: #deffec;
    }
  }

  .yellow {
    .card-header {
      background-color: #f7dc6f !important;
    }

    .card-body {
      background-color: #fcf3cf;
    }
  }
  .red {
    .card-header {
      background-color: #f1948a !important;
    }
    .card-body {
      background-color: #fadbd8;
    }
  }

  .purple {
    .card-header {
      background-color: #bb8fce !important;
    }

    .card-body {
      background-color: #e8daef;
    }
  }

  .blue {
    .card-header {
      background-color: #3498db !important;
    }

    .card-body {
      background-color: #d6eaf8;
    }
  }

  .light-red {
    .card-header {
      background-color: #ffe6e6 !important;
    }

    .card-body {
      background-color: #fcf5f5;
    }
  }

  .orange {
    .card-header {
      background-color: #ff944d !important;
    }

    .card-body {
      background-color: #ffc299;
    }
  }

  .General-Information {
    .card-header {
      background-color: #abebc6 !important;
    }

    .card-body {
      background-color: #deffec;
    }

    .Risk-Level {
      .card-header {
        background-color: #abebc6 !important;
      }

      .card-body {
        background-color: #deffec;
      }
    }
  }

  .Lock-Tag-Try {
    .card-header {
      background-color: #f7dc6f !important;
    }

    .card-body {
      background-color: #fcf3cf;
    }
  }
  .Hot-Work {
    .card-header {
      background-color: #f1948a !important;
    }
    .card-body {
      background-color: #fadbd8;
    }
  }

  .Electrical {
    .card-header {
      background-color: #bb8fce !important;
    }

    .card-body {
      background-color: #e8daef;
    }
  }

  .Special-Instructions {
    .card-header {
      background-color: #abebc6 !important;
    }

    .card-body {
      background-color: #deffec;
    }
  }
  .Confined-Space {
    .card-header {
      background-color: #3498db !important;
    }

    .card-body {
      background-color: #d6eaf8;
    }
  }

  .ApproveButton div.checkbox label.form-check-label input.form-check-input {
    visibility: hidden;
  }

  .ApproveButton div.checkbox label.form-check-label span:hover {
    background-color: #ff6060;
  }
  .ApproveButton div.checkbox label.form-check-label span {
    background: #ff8080;
    cursor: pointer;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.375rem 0.75rem;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
  }

  .ApproveButton div.checkbox label.form-check-label input:checked + span {
    background: #008040 !important;
  }

  .ApproveButton div.checkbox label.form-check-label input:disabled + span {
    opacity: 0.65;
    cursor: initial;
  }
}

.sitemap-pointer-link {
  color: var(--primary);
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.uploadFileCss a{
  color: var(--primary) !important;
  &:hover {
    text-decoration: underline !important;
    cursor: pointer !important;
  }
}

.terminalsDeviationRequired {
  font-weight: bolder;
}

.deenergizedElectricalPanel .bg-info{
  background-color: #C576F6 !important;
}

.energizedElectricalPanel .bg-danger{
  background-color: #FF5C5C !important;
}